import React from "react"
import styled from "styled-components"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

import { Section, Container } from "../components/global"

const Delete = () => (
  <Layout>
    <Section id="features">
      <StyledContainer>
        <SEO title="Delete" />
          <h1>Request Account Deletion =(</h1>
          <p>Please email theschnaz@trydayapp.com from the email address associated with your account.</p>
      </StyledContainer>
    </Section>
  </Layout>
)

export default Delete

const StyledContainer = styled(Container)``